import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Pill from '../../shared/Pill/Pill'
import Image from '../../shared/Image/Image'
import Embed from '../../shared/Embed/Embed'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import ShareLinks from '../../shared/ShareLinks/ShareLinks'
import Heading from '../../shared/Heading/Heading'
import Button from '../../shared/Button/Button'

const BlogArticlePressReleaseView = ({ children, data, url }) => {
  return (
    <article>
      <header>
        <div className='pt-24 lg:pt-40 bg-gray-lighter mb-10'>
          <div className='max-w-3xl mx-auto px-4 mb-10'>
            <div className='text-center'>
              <Pill className='mb-4'>
                {data.categories[0].category.document.data.title.text}
              </Pill>
              <h2>{data.title.text}</h2>
            </div>
          </div>
          <div className='relative'>
            <div className='max-w-4xl mx-auto px-4 relative z-10'>
              <Image
                fallbackAlt={data.title.text}
                {...data.featured_image}
              />
            </div>
            <div className='absolute h-1/2 bottom-0 left-0 w-full bg-white' />
          </div>
        </div>
        <Container className='text-center'>
          <ShareLinks
            className='mb-12'
            title={data.title.text}
            url={url}
          />
        </Container>
      </header>
      <div className='space-y-10 mb-24'>
        {data.body.map((slice) => {
          switch (slice.slice_type) {
            case 'text':
              return (
                <div
                  key={slice.id}
                  className='max-w-3xl mx-auto px-4'
                >
                  <HtmlContent
                    html={slice.primary.rich_text.html}
                    className='c-prose'
                  />
                </div>
              )

            case 'text_with_inline':
              return (
                <div
                  key={slice.id}
                  className='max-w-3xl mx-auto px-4'
                >
                  <HtmlContent
                    // Replace bold/italic and italic/bold with code tags, as
                    // it should rendered as be inline code
                    html={slice.primary.rich_text.html.replace(/<strong><em>|<em><strong>/gm, '<code>').replace(/<\/strong><\/em>|<\/em><\/strong>/gm, '</code>')}
                    className='c-prose'
                  />
                </div>
              )

            case 'image':
              return (
                <div
                  key={slice.id}
                  className={classNames('mx-auto px-4 space-y-3', {
                    'max-w-xl': slice.primary.width === 'half',
                    'max-w-4xl': slice.primary.width !== 'half'
                  })}
                >
                  <Image
                    fallbackAlt={data.title.text}
                    {...slice.primary.image}
                  />
                  {slice.primary.image_caption.html && (
                    <HtmlContent
                      html={slice.primary.image_caption.html}
                      className='border-l-2 border-primary pl-3 text-xs c-prose'
                    />
                  )}
                </div>
              )

            case 'embed':
              return (
                <div
                  key={slice.id}
                  className='max-w-4xl mx-auto px-4'
                >
                  <Embed
                    embed={slice.primary.embed}
                    title={slice.primary.embed.title}
                  />
                </div>
              )

            case 'blockquote':
              return (
                <div
                  key={slice.id}
                  className='max-w-3xl mx-auto px-4'
                >
                  <blockquote className='border-l-2 border-primary pl-6'>
                    <HtmlContent
                      html={slice.primary.blockquote_body.html}
                      className='h5 font-normal text-midnight c-prose'
                    />
                    {slice.primary.blockquote_caption.html && (
                      <HtmlContent
                        html={slice.primary.blockquote_caption.html}
                        element='footer'
                        className='pt-4 text-xs uppercase tracking-wider font-medium c-prose'
                      />
                    )}
                  </blockquote>
                </div>
              )

            case 'downloads_list':
              return (
                <div
                  key={slice.id}
                  className='max-w-3xl mx-auto px-4'
                >
                  <Heading
                    className='h3 mb-6'
                    {...slice.primary.downloads_list_title}
                  />
                  <div className='space-y-6'>
                    {slice.items.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className='shadow-lg sm:flex items-center justify-between sm:space-x-4 px-4 sm:px-10 py-6 sm:py-12 text-center sm:text-left'
                        >
                          <div className='mb-6 sm:mb-0 space-y-1'>
                            <h4 className='text-primary'>{item.header.text}</h4>
                            {item.description.html && (
                              <HtmlContent
                                html={item.description.html}
                                className='c-prose'
                              />
                            )}
                          </div>

                          <Button
                            color='primary'
                            className='w-full sm:w-auto'
                            icon
                            {...item.url}
                          >
                            {item.label}
                          </Button>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )

            default:
              console.error('Component not found for type: ', slice.slice_type)
          }
        })}
      </div>
      <div className='max-w-4xl mx-auto px-4 mb-12'>
        <div className='border-b border-gray-lighter' />
      </div>
      {children}
    </article>
  )
}

BlogArticlePressReleaseView.propTypes = {
  data: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired
}

export default BlogArticlePressReleaseView
